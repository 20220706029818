/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-datepicker/dist/react-datepicker.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
@import "~font-awesome/scss/font-awesome";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/preview";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";

.dropdown .dropdown-toggle:after {
    color: black;
}

.btn-primary {
    border-color: aliceblue;
}
.logo-gen3 {
    justify-content: center;
}
.navbar .navbar-menu-wrapper {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: rgb(7, 0, 0);
    padding-left: 15px;
    padding-right: 15px;
    //    width: calc(100% - 255px);
    height: 63px;
    background: white;
}
.justify-content-between,
.page-header {
    justify-content: flex-end !important;
}

.navbar .navbar-menu-wrapper .navbar-toggler {
    border: 0;
    color: #052047;
    margin-right: 0;
}
.sidebar .nav .nav-item .nav-link .menu-icon {
    margin-right: 1.25rem;
    width: 16px;
    line-height: 1;
    font-size: 18px;
    color: #fffbfb;
}
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item.active > .nav-link i {
    color: white;
}

.sidebar
    > .nav:not(.sub-menu)
    > .nav-item:hover:not(.nav-profile):not(.hover-open)
    > .nav-link:not([aria-expanded="true"]) {
    background: #0a0a0a;
    padding-left: 65px;
}
.sidebar > .nav .nav-item .nav-link .menu-title {
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    vertical-align: middle;
    font-weight: 500;
    color: white;
}
.navbar .navbar-menu-wrapper .search-form .form-control {
    height: 5vh;
    width: 35vw;
    margin-top: 100;
}

form {
    display: block;
    margin-top: 1em;
}
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item .dropdown .dropdown-toggle:after {
    content: "\F140";
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    border: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 12px;
}
.rounded-circle[class*="img"],
.settings-panel .color-tiles .tiles[class*="img"] {
    position: relative;
    overflow: hidden;
    background-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.sidebar .nav .nav-item.active > .nav-link .nav-item active .sidebar .nav > .nav-item {
    color: rgb(0, 0, 0);
}
.sidebar {
    min-height: calc(100vh - 63px);
    background: linear-gradient(0deg, #5768f3, #1c45ef);
    font-family: Roboto, sans-serif;
}
.sidebar
    > .nav:not(.sub-menu)
    > .nav-item:hover:not(.nav-profile):not(.hover-open)
    > .nav-link:not([aria-expanded="true"]) {
    background: #0f25d5;
    padding-left: 65px;
}
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: flex;
    align-items: center;
    background: #0f25d5;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-profile) > .nav-link {
    background: #0070e1;
    color: #4a4a4a;
}
.mdi-domain {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
    transition-duration: 0.3s;
}
.mdi-home {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
}
.mdi-cog-outline {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
}
.mdi-account-group {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
}
.mdi-calendar-star {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
}
.mdi-table-large {
    position: absolute;
    left: 30px;
    right: 50%;
    width: 10px;
    height: 10px;
}
.sidebar > .nav:not(.sub-menu) > .nav-item > .nav-link {
    background: transparent;
    transition-duration: 0.2s;
}

.modal-title {
    color: black;
}

.message-list-item {
    list-style-type: none;
}

.centered-button-container {
    text-align: center;
    align-items: center;
    margin-top: 20px; /* Add vertical space above the button */
}

.textarea-container {
    margin-bottom: 20px; /* Add vertical space below the textarea */
}

.close-button {
    color: red; /* Color code the close button */
    font-size: 1.5rem;
    cursor: pointer;
    border: none;
    background: none;
    top: 10px;
    right: 10px;
}

.modal-title-bold {
    font-weight: bold;
  }

  .blue-placeholder::placeholder {
    color: blue;
}
